<template lang="html">
  <div class="legend">
    <label>
      {{ title }}
    </label>
    <table>
      <tbody>
        <tr v-for="color of colors" :key="color.hex">
          <td :style="{ height: '18px', width: '18px', background: color.hex }"></td>
          <td v-if="color.range[0] === -Infinity">{{ color.range[1].toLocaleString() }}</td>
          <td v-else-if="color.range[1] === Infinity">{{ color.range[0].toLocaleString() }}+</td>
          <td v-else>
            {{ color.range[0].toLocaleString() }} - {{ color.range[1].toLocaleString() }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    colors: { type: Array, required: true },
  },
};
</script>

<style lang="scss" scoped>
.legend {
  padding: 16px;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  border-radius: 8px;

  label {
    font-weight: bold;
  }
}
</style>
